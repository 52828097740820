<template>
  <div class="row">
    <breadcrumb-box/>
    <h2>Help</h2>
  </div>
</template>

<script>
import BreadcrumbBox from "@/components/elements/BreadcrumbBox";

export default {
  name: "HelpView",
  components: {BreadcrumbBox}

}
</script>

<style scoped>

</style>