<template>
  <!-- Отображение layouts, mainLayout, authLayout -->
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>
<style lang="sass"></style>
