<template>
<div>

</div>
</template>

<script>
import {useHead} from "@unhead/vue";

export default {
  name: "SearchView",
  data() {
    return {}
  },
  mounted() {
    useHead({
      title: `Wallone • Поиск за запросу:`
    })
  }
}
</script>

<style scoped>

</style>