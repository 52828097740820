<template>
  <div class="row">
    <breadcrumb-box/>
  </div>
</template>

<script>
import BreadcrumbBox from "@/components/elements/BreadcrumbBox";

export default {
  name: "DevView",
  components: {BreadcrumbBox}
}
</script>

<style scoped>

</style>